import { Route, Switch } from 'react-router-dom';

import { Franchisees } from '@/screen/pages/Franchisees';
import { useBuildRoute } from '@resources/hooks/useBuildRoute';

export function FranchiseesRoute(): JSX.Element {
  const { make } = useBuildRoute('/franchisees');

  return (
    <Switch>
      <Route path={make('/')} exact>
        <Franchisees />
      </Route>
    </Switch>
  );
}
