/* eslint-disable react/jsx-no-constructed-context-values */
import {
  CreateSessionLoginHttpData,
  GetMeHttpData,
  SessionLoginData,
  EmployeeDocument,
} from '@hitechline/urbanonorte-types/dtos/manager/api';
import { PropsWithChildren } from '@hitechline/urbanonorte-types/modules/react';
import { createContext, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { api } from '@modules/services/api';
import { actions } from '@store/modules/auth/actions';

type User = EmployeeDocument;

export interface AuthContextData {
  ready: boolean;
  user: User | null;
  signIn(data: SessionLoginData): Promise<void>;
  authenticate(data: CreateSessionLoginHttpData): void;
  retrieveUser: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData,
);

export function AuthProvider({ children }: PropsWithChildren): JSX.Element {
  const { push } = useHistory();

  const dispatch = useDispatch();
  const { signed } = useSelector(({ auth }) => auth);

  const [ready, updateReady] = useState(false);
  const [user, updateUser] = useState<User | null>(null);

  const authenticate = useCallback(
    (data: CreateSessionLoginHttpData) => {
      updateUser(data.user);

      setTimeout(() => {
        dispatch(actions.signInRequest({ token: data.token }));

        push('/app');
      }, 0);
    },
    [push, dispatch, updateUser],
  );

  const signIn = useCallback(
    async ({ email, password }: SessionLoginData) => {
      const { data: loginData } = await api.post<CreateSessionLoginHttpData>(
        'sessions/login',
        {
          email,
          password,
        },
      );

      authenticate(loginData);
    },
    [authenticate],
  );

  const retrieveUser = useCallback(async () => {
    try {
      const { data: employeeData } = await api.get<GetMeHttpData>('me');

      updateUser(employeeData);
    } catch {
      dispatch(actions.logOut({}));
    }
  }, [updateUser, dispatch]);

  const load = useCallback(async () => {
    if (!signed) {
      updateReady(true);
      return;
    }

    await retrieveUser();

    updateReady(true);
  }, [signed, retrieveUser]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <AuthContext.Provider
      value={{
        ready,
        user,
        signIn,
        authenticate,
        retrieveUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
