import { Route, Switch, Redirect } from 'react-router-dom';

import { AssigneesRoute } from './Assignees.routes';
import { DriversRoutes } from './Driver.routes';
import { FranchiseesRoute } from './Franchisees.routes';
import { MainRoutes } from './Main.routes';
import { UserRoutes } from './User.routes';

export function RoutesManager(): JSX.Element {
  return (
    <Switch>
      <Route path="/assignees">
        <AssigneesRoute />
      </Route>

      <Route path="/drivers">
        <DriversRoutes />
      </Route>

      <Route path="/franchisees">
        <FranchiseesRoute />
      </Route>

      <Route path="/user">
        <UserRoutes />
      </Route>

      <Route path="/">
        <MainRoutes />
      </Route>

      {/* 404 */}

      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
}
