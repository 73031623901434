import { useCallback } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { FiCalendar, FiPhone } from 'react-icons/fi';
import { MdMailOutline } from 'react-icons/md';

import { useAuth } from '@/resources/hooks/useAuth';
import { Avatar } from '@/screen/components/ui/Avatar';
import { Input } from '@screen/components/ui/Input';

import {
  Container,
  Form,
  AvatarContainer,
  FullName,
  Birthdate,
  Email,
  Phone,
} from './styles';

export function PersonalData(): JSX.Element {
  const { user } = useAuth();

  const handleSubmit = useCallback(() => {
    //
  }, []);

  return (
    <Container>
      <Form initialData={user ?? {}} onSubmit={handleSubmit}>
        <AvatarContainer>
          <Avatar name={user?.full_name} round />
        </AvatarContainer>

        <FullName>
          <Input
            name="full_name"
            label="Nome"
            placeholder="Seu Nome"
            icon={AiOutlineUser}
          />
        </FullName>

        <Birthdate>
          <Input
            name="birthdate"
            type="date"
            label="Data de nascimento"
            icon={FiCalendar}
          />
        </Birthdate>

        <Email>
          <Input
            name="email"
            label="E-mail"
            placeholder="example@marinetes.com.br"
            icon={MdMailOutline}
          />
        </Email>

        <Phone>
          <Input
            name="phone"
            label="Telefone"
            mask="(99) \9 9999-9999" // eslint-disable-line no-nonoctal-decimal-escape
            placeholder="Telefone"
            icon={FiPhone}
          />
        </Phone>
      </Form>
    </Container>
  );
}
