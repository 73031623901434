import { Container, Content } from './styles';

export function Footer(): JSX.Element {
  return (
    <Container>
      <Content className="bowl-content">
        <p>Todos os direitos reservados</p>
        <span>
          Powered by <a href="https://hitechline.com.br">Hitechline</a>
        </span>
      </Content>
    </Container>
  );
}
