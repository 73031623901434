import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { MdKeyboardArrowDown } from 'react-icons/md';

import { Z_INDEX } from '@screen/styles/config';

interface InfoContentProps {
  withIcon: boolean;
}

interface ArrowIconProps {
  open: boolean;
}

const MAIN_PADDING = '20px';

export const Container = styled.div`
  position: relative;
  display: flex;

  width: 100%;

  flex-direction: column;
  justify-content: center;

  color: var(--text-color);

  &.disabled {
    opacity: 0.5;

    &,
    button {
      cursor: not-allowed;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }
  }
`;

export const Content = styled.button`
  width: 100%;
  height: 60px;
  position: relative;

  display: flex;

  overflow: hidden;
  border-radius: var(--wai-main-border-radius);

  .icon {
    width: 55px;
    height: 60px;
    padding: 15px;

    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: var(--color-light-favorite) !important;

    svg {
      width: 20px;
      height: auto;

      color: var(--color-white) !important;
    }
  }

  ${Container}.error & {
    border-color: var(--color-error);

    .icon {
      background-color: var(--color-error) !important;

      svg {
        color: var(--color-white) !important;
      }
    }
  }
`;

export const InfoContent = styled.div<InfoContentProps>`
  width: 100%;
  height: 60px;
  padding: ${({ withIcon }) =>
    withIcon ? '10px 20px 10px 10px' : '10px 20px'};

  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  background-color: var(--color-white);

  div {
    display: flex;
    flex-direction: column;
    gap: 4px;

    text-align: left;

    span {
      color: var(--color-input-label);
    }

    p {
      color: var(--color-grey);
    }
  }
`;

export const ArrowIcon = styled(MdKeyboardArrowDown)<ArrowIconProps>`
  width: 30px;
  height: 30px;

  left: calc(100% - 30px);

  flex-shrink: 0;

  color: var(--color-grey);

  ${({ open }) =>
    open &&
    css`
      transform: rotate(-180deg);
    `}
`;

export const Options = styled.div`
  width: 100%;

  padding: 6px 0;

  position: absolute;
  z-index: ${Z_INDEX.MAX};
  top: calc(100% + 6px);

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  border-radius: var(--wai-main-border-radius);
  background-color: var(--color-white);

  button {
    width: auto;
    height: auto;
    padding: ${MAIN_PADDING};

    display: flex;
    flex-direction: column;

    font-size: 15px;
    font-weight: 300;
    text-align: left;
    line-height: 17px;
    color: var(--color-black);

    border: none;
    outline: none;
    cursor: pointer;

    &:hover {
      background-color: var(--color-white-grey);
    }
  }
`;

export const Error = styled.div`
  margin-top: 10px;

  font-size: 12px;
  color: var(--color-error);

  text-transform: uppercase;

  > span {
    display: block;

    text-align: left;
  }
`;
