/* eslint-disable no-use-before-define */
import { mergeClassNames } from '@hitechline/reactools';
import { useField } from '@unform/core';
import {
  useEffect,
  useCallback,
  useMemo,
  useState,
  useRef,
  FocusEventHandler,
  InputHTMLAttributes,
  DetailedHTMLProps,
} from 'react';
import type { IconType } from 'react-icons';
import type { Props as InputMaskProps } from 'react-input-mask';

import { generateRandomString } from '@/modules/utils/generateRandomString';

import {
  Container,
  Content,
  InfoContainer,
  InputElement,
  Error,
} from './styles';

export type ElementProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export type MainInputProps = Omit<BaseInputProps, 'basedClassName'>;

export interface BaseInputProps
  extends Omit<ElementProps | InputMaskProps, 'mask' | 'onBlur'> {
  name: string;
  label?: string;
  icon?: IconType;
  basedClassName: string;
  mask?: InputMaskProps['mask'];
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
}

export function BaseInput({
  name,
  label,
  mask,
  className,
  basedClassName,
  icon: Icon,
  disabled,
  onFocus,
  onBlur,
  ...rest
}: BaseInputProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);

  const { error, fieldName, clearError, defaultValue, registerField } =
    useField(name);

  const [isFocused, setIsFocused] = useState(false);

  const id = useMemo(() => generateRandomString(), []);

  const handleBlur: FocusEventHandler<HTMLInputElement> = useCallback(
    (...props) => {
      setIsFocused(false);

      onBlur?.(...props);
    },
    [onBlur],
  );

  const handleFocus: FocusEventHandler<HTMLInputElement> = useCallback(
    (...props) => {
      clearError();
      setIsFocused(true);

      onFocus?.(...props);
    },
    [onFocus, clearError],
  );

  useEffect(() => {
    registerField({
      ref: inputRef,
      name: fieldName,
      getValue: () => inputRef.current?.value ?? '',
      clearValue: () => {
        if (inputRef.current?.value) {
          inputRef.current.value = '';
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container
      className={mergeClassNames(basedClassName, className, {
        'disabled': Boolean(disabled),
        'error': Boolean(error),
        'focused': isFocused,
        'with-icon': Boolean(Icon),
      })}
      data-disabled={disabled}
    >
      <Content>
        {Icon && (
          <div className="icon">
            <Icon />
          </div>
        )}

        <InfoContainer withIcon={Boolean(Icon)}>
          {label && <label htmlFor={id}>{label}</label>}

          <InputElement
            {...rest}
            disabled={disabled}
            name={name}
            onBlur={handleBlur}
            onFocus={handleFocus}
            defaultValue={defaultValue}
            mask={mask as any}
          >
            {(inputProps: any) => (
              <input ref={inputRef} disabled={disabled} {...inputProps} />
            )}
          </InputElement>
        </InfoContainer>
      </Content>

      {error && (
        <Error>
          <span>{error}</span>
        </Error>
      )}
    </Container>
  );
}
