import styled from '@emotion/styled';
import InputMaskComponent from 'react-input-mask';

interface InfoContainerProps {
  withIcon: boolean;
}

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  > label {
    display: block;
    width: max-content;
    margin-bottom: 3px;
  }

  &.disabled {
    opacity: 0.5;

    &,
    input {
      cursor: not-allowed;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  position: relative;

  display: flex;

  overflow: hidden;
  border-radius: var(--wai-main-border-radius);

  .icon {
    padding: 15px;
    width: 55px;

    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: var(--color-light-favorite) !important;

    svg {
      width: 20px;
      height: auto;

      color: var(--icon-color) !important;
    }
  }

  ${Container}.error & {
    border-color: var(--color-error);

    .icon {
      background-color: var(--color-error) !important;

      svg {
        color: var(--color-white) !important;
      }
    }
  }
`;

export const InfoContainer = styled.div<InfoContainerProps>`
  width: 100%;
  height: auto;
  padding: ${({ withIcon }) =>
    withIcon ? '10px 20px 10px 10px' : '10px 20px'};

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;

  font-size: 16px;

  background-color: var(--input-background);

  > label {
    color: var(--label-color);
  }
`;

export const InputElement = styled(InputMaskComponent)`
  width: 100%;

  color: var(--input-color);

  background-color: transparent;

  &::placeholder {
    color: var(--input-placeholder);
  }
`;

export const Error = styled.div`
  margin-top: 10px;

  font-size: 12px;
  color: var(--color-error);

  text-transform: uppercase;

  > span {
    display: block;

    text-align: left;
  }
`;
