import { apply } from '@/resources/cases/apply';
import { Logged } from '@/resources/cases/Logged';
import { DefaultLayout } from '@/screen/layouts/DefaultLayout';

export const Assignees = apply(
  (): JSX.Element => {
    return <div>Assignees</div>;
  },

  {
    layout: DefaultLayout,
    cases: [Logged],
  },
);
