import { Route, Switch } from 'react-router-dom';

import { Drivers } from '@/screen/pages/Driver';
import { DriverShow } from '@/screen/pages/Driver/pages/Show';
import { useBuildRoute } from '@resources/hooks/useBuildRoute';

export function DriversRoutes(): JSX.Element {
  const { make } = useBuildRoute('/drivers');

  return (
    <Switch>
      <Route path={make('/')} exact>
        <Drivers />
      </Route>

      <Route path={make('/:id')} exact>
        <DriverShow />
      </Route>
    </Switch>
  );
}
