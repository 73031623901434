import { useContext } from 'react';
import { AiFillFlag, AiOutlineFieldNumber } from 'react-icons/ai';
import { FaCity, FaStreetView } from 'react-icons/fa';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { ImHome } from 'react-icons/im';

import { CEPManager } from '@resources/managers/CEPManager';
import { driverAddressUpdateSchema } from '@resources/schemas/driver/update';
import { Input } from '@screen/components/ui/Input';
import { ShowManageContext } from '@screen/pages/Driver/pages/Show/ShowManageContext';

import {
  Container,
  Form,
  FormMain,
  ButtonContainer,
  SubmitButton,
} from './styles';

export function Address(): JSX.Element {
  const { driver, handleAddressEdit } = useContext(ShowManageContext);

  return (
    <Container>
      <Form
        initialData={driver.address}
        schema={driverAddressUpdateSchema}
        onSubmit={handleAddressEdit}
      >
        <FormMain>
          <CEPManager>
            <Input
              name="zip_code"
              label="CEP"
              placeholder="12345-678"
              icon={HiOutlineLocationMarker}
            />

            <Input
              name="street"
              label="Rua"
              placeholder="Rua Martin Rodrigues"
              icon={FaStreetView}
            />

            <Input
              name="number"
              label="Número"
              placeholder="000"
              icon={AiOutlineFieldNumber}
            />

            <Input
              name="neighborhood"
              label="Bairro"
              placeholder="Centro"
              icon={ImHome}
            />

            <Input
              name="state"
              label="Estado"
              placeholder="SP"
              icon={AiFillFlag}
            />

            <Input
              name="city"
              label="Cidade"
              placeholder="Jundiaí"
              icon={FaCity}
            />
          </CEPManager>
        </FormMain>

        <ButtonContainer>
          <SubmitButton type="submit">Salvar</SubmitButton>
        </ButtonContainer>
      </Form>
    </Container>
  );
}
