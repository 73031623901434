import styled from '@emotion/styled';

import { Form as FormDefault } from '@/screen/components/forward/Form';
import { Button } from '@/screen/components/ui/Button';

export const Container = styled.div`
  width: 100%;
`;

export const Form = styled(FormDefault)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const FormMain = styled.div`
  width: 100%;

  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    'avatar avaliation'
    'avatar email'
    'avatar document'
    'full_name phone'
    'birthdate gender'
    'cnh cnh_type';
`;

export const AvatarContainer = styled.div`
  padding: 17px;

  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: avatar;

  border-radius: var(--wai-main-border-radius);
  background-color: var(--color-white);
`;

export const Avaliation = styled.div`
  width: 100%;
  height: 60px;

  display: flex;
  grid-area: avaliation;

  border-radius: var(--wai-main-border-radius);
  overflow: hidden;

  .icon {
    padding: 15px;
    width: 55px;

    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: var(--color-light-favorite) !important;

    svg {
      width: 20px;
      height: auto;

      color: var(--color-white) !important;
    }
  }
`;

export const AvaliationContent = styled.div`
  width: 100%;
  padding: 10px;

  display: flex;
  flex-direction: column;
  gap: 4px;

  background-color: var(--color-white);

  span {
    display: block;

    font-size: 16px;
    color: var(--color-input-label);
  }
`;

export const Email = styled.div`
  grid-area: email;
`;

export const Phone = styled.div`
  grid-area: phone;
`;

export const FullName = styled.div`
  grid-area: full_name;
`;

export const Document = styled.div`
  grid-area: document;
`;

export const Birthdate = styled.div`
  grid-area: birthdate;
`;

export const Gender = styled.div`
  grid-area: gender;
`;

export const Cnh = styled.div`
  grid-area: cnh;
`;

export const CnhType = styled.div`
  grid-area: cnh_type;
`;

export const ButtonContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;
`;

export const SubmitButton = styled(Button)`
  max-width: 200px;
`;
