import { Route, Switch } from 'react-router-dom';

import { User } from '@/screen/pages/User';
import { useBuildRoute } from '@resources/hooks/useBuildRoute';

export function UserRoutes(): JSX.Element {
  const { make } = useBuildRoute('/user');

  return (
    <Switch>
      <Route path={make('/')} exact>
        <User />
      </Route>
    </Switch>
  );
}
