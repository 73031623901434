import type { FranchiseeDocument } from '@hitechline/urbanonorte-types/dtos/manager/api';
import { FiChevronRight } from 'react-icons/fi';
import { IoIosSend } from 'react-icons/io';

import { Container, Row, RowContent, IconButton } from './styles';

interface FranchiseeTableRowProps {
  franchisee: FranchiseeDocument;
}

export function FranchiseeTableRow({
  franchisee: { full_name, individual_registration, email },
}: FranchiseeTableRowProps): JSX.Element {
  return (
    <Container>
      <Row>{full_name}</Row>
      <Row>{individual_registration}</Row>
      <Row>{email}</Row>
      <Row>
        <RowContent>
          <IconButton>
            <FiChevronRight size="40px" color="var(--color-white)" />
          </IconButton>

          <IconButton>
            <IoIosSend
              size="20px"
              color="var(--color-white)"
              title="Enviar Notificação"
            />
          </IconButton>
        </RowContent>
      </Row>
    </Container>
  );
}
