import { css } from '@emotion/css';

import { BaseTextarea, MainTextareaProps } from './BaseTextarea';

export type TextareaProps = MainTextareaProps;

export function Textarea(props: TextareaProps): JSX.Element {
  return (
    <BaseTextarea
      {...props}
      basedClassName={css`
        --icon-color: var(--color-white);
        --error-color: var(--color-error);
        --label-color: var(--color-input-label);

        --textarea-color: var(--color-grey);
        --textarea-placeholder: var(--color-teen-grey);
        --textarea-background: var(--color-white);
      `}
    />
  );
}
