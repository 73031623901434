import type { IconType } from 'react-icons';
import { AiFillCar } from 'react-icons/ai';
import {
  FiHome,
  //  FiUsers,
  //   FiTruck
} from 'react-icons/fi';
// import { IoLogoBuffer } from 'react-icons/io';
import {
  MdRoomService,
  // MdAttachMoney
} from 'react-icons/md';

export interface LinkType {
  href: string;
  title: string;
  employee?: true;
}

export interface LinkWithIconType extends LinkType {
  href: string;
  title: string;
  icon: IconType;
}

export const drawerLinks: LinkWithIconType[] = [
  {
    title: 'Home',
    href: '/app',
    icon: FiHome,
  },
  // {
  //   title: 'Fornecedores',
  //   href: '/fornecedores',
  //   icon: FiTruck,
  //   employee: true,
  // },
  {
    title: 'Motoristas',
    href: '/drivers',
    icon: AiFillCar,
    employee: true,
  },
  {
    title: 'Cessionários',
    href: '/franchisees',
    icon: MdRoomService,
    employee: true,
  },
  // {
  //   title: 'Financeiro',
  //   href: '/financial',
  //   icon: MdAttachMoney,
  //   employee: true,
  // },
  // {
  //   title: 'Usuários',
  //   href: '/users',
  //   icon: FiUsers,
  //   employee: true,
  // },
  // {
  //   title: 'Logs',
  //   href: '/logs',
  //   icon: IoLogoBuffer,
  //   employee: true,
  // },
];
