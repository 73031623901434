import type {
  DriverDocument,
  DriverUpdateData,
  DriverAddressUpdateData,
  DriverStatusUpdateData,
} from '@hitechline/urbanonorte-types/dtos/manager/api';
import { createContext } from 'react';

export interface ShowManageContextData {
  retrieving: boolean;
  driver: DriverDocument;
  retrieveDriver(): Promise<void>;
  handlePersonalDataEdit(data: DriverUpdateData): Promise<void>;
  handleAddressEdit(data: DriverAddressUpdateData): Promise<void>;
  handleStatusEdit(data: DriverStatusUpdateData): Promise<void>;
}

export const ShowManageContext = createContext<ShowManageContextData>(
  {} as ShowManageContextData,
);
