import styled from '@emotion/styled';

export const Container = styled.main`
  display: flex;
  justify-content: center;

  gap: 2rem;
`;

export const Box = styled.div`
  padding: 1rem;

  border-radius: 10px;
  background-color: var(--color-black);

  span {
    color: var(--color-teen-grey);
  }
`;
