import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface ContainerProps {
  size: string;
}

export const Container = styled.div<ContainerProps>`
  padding: 20px;

  border-radius: var(--wai-main-border-radius);
  box-shadow: var(--box-shadow);

  > span {
    display: block;

    ${({ size }) => css`
      width: ${size};
      height: ${size};
    `}

    border: 4px solid rgba(var(--color-white-rgb), 0.2);
    border-left: 4px solid var(--color-light-favorite);
    border-radius: 50%;

    animation: spinner 300ms linear infinite;

    @keyframes spinner {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
`;
