import { useContext, useMemo } from 'react';
import { HiOutlineAdjustments } from 'react-icons/hi';
import { IoIosAlert } from 'react-icons/io';

import { Table } from '@screen/components/ui/Table';
import { ShowManageContext } from '@screen/pages/Driver/pages/Show/ShowManageContext';

import {
  Container,
  Header,
  FilterButton,
  Input,
  Main,
  StatusMessage,
} from './styles';
import { CarTableRow } from '../../components/CarTableRow';

export function Vehicles(): JSX.Element {
  const { driver } = useContext(ShowManageContext);

  const carTableElement = useMemo(() => {
    const headers = ['Placa', 'Marca', 'Model', 'Pendente', ''];
    const rows = driver.cars.map(car => <CarTableRow key={car.id} car={car} />);

    return <Table headers={headers} rows={rows} />;
  }, [driver.cars]);

  return (
    <Container>
      <Header>
        <FilterButton disabled>
          <HiOutlineAdjustments size={25} color="white" />
        </FilterButton>

        <Input placeholder="Pesquisar..." disabled />
      </Header>

      {driver.cars.length > 0 ? (
        <Main>{carTableElement}</Main>
      ) : (
        <StatusMessage>
          <IoIosAlert size="80px" color="var(--color-strong-grey)" />

          <p>Nenhum veículo cadastrado!</p>
        </StatusMessage>
      )}
    </Container>
  );
}
