import { Container } from './styles';

interface SpinnerProps {
  size?: string;
}

export function Spinner({ size = '40px' }: SpinnerProps): JSX.Element {
  return (
    <Container className="spinner" size={size}>
      <span />
    </Container>
  );
}
