import styled from '@emotion/styled';

import { Form as FormDefault } from '@/screen/components/forward/Form';

export const Container = styled.div`
  width: 100%;
`;

export const Form = styled(FormDefault)`
  width: 100%;

  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(50px, auto);
  grid-template-areas:
    'avatar email'
    'avatar phone'
    'full_name birthdate';
`;

export const AvatarContainer = styled.div`
  padding: 17px;

  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: avatar;

  border-radius: var(--wai-main-border-radius);
  background-color: var(--color-white);
`;

export const FullName = styled.div`
  grid-area: full_name;
`;

export const Birthdate = styled.div`
  grid-area: birthdate;
`;

export const Email = styled.div`
  grid-area: email;
`;

export const Phone = styled.div`
  grid-area: phone;
`;
