/* eslint-disable no-use-before-define */
import { mergeClassNames } from '@hitechline/reactools';
import { useField } from '@unform/core';
import {
  useEffect,
  useCallback,
  useMemo,
  useState,
  useRef,
  FocusEventHandler,
  TextareaHTMLAttributes,
  DetailedHTMLProps,
} from 'react';

import { generateRandomString } from '@/modules/utils/generateRandomString';

import { Container, Content, TextareaElement, Error } from './styles';

export type ElementProps = DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

export type MainTextareaProps = Omit<BaseTextareaProps, 'basedClassName'>;

export interface BaseTextareaProps
  extends Omit<ElementProps, 'mask' | 'onBlur'> {
  name: string;
  label?: string;
  basedClassName: string;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLTextAreaElement>;
}

export function BaseTextarea({
  name,
  label,
  className,
  basedClassName,
  disabled,
  onFocus,
  onBlur,
  ...rest
}: BaseTextareaProps): JSX.Element {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const { error, fieldName, clearError, defaultValue, registerField } =
    useField(name);

  const [isFocused, setIsFocused] = useState(false);

  const id = useMemo(() => generateRandomString(), []);

  const handleBlur: FocusEventHandler<HTMLTextAreaElement> = useCallback(
    (...props) => {
      setIsFocused(false);

      onBlur?.(...props);
    },
    [onBlur],
  );

  const handleFocus: FocusEventHandler<HTMLTextAreaElement> = useCallback(
    (...props) => {
      clearError();
      setIsFocused(true);

      onFocus?.(...props);
    },
    [onFocus, clearError],
  );

  useEffect(() => {
    registerField({
      ref: textareaRef,
      name: fieldName,
      getValue: () => textareaRef.current?.value ?? '',
      clearValue: () => {
        if (textareaRef.current?.value) {
          textareaRef.current.value = '';
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container
      className={mergeClassNames(basedClassName, className, {
        disabled: Boolean(disabled),
        error: Boolean(error),
        focused: isFocused,
      })}
      data-disabled={disabled}
    >
      <Content>
        {label && <label htmlFor={id}>{label}</label>}

        <TextareaElement
          ref={textareaRef}
          disabled={disabled}
          name={name}
          onBlur={handleBlur}
          onFocus={handleFocus}
          defaultValue={defaultValue}
          {...rest}
        />
      </Content>

      {error && (
        <Error>
          <span>{error}</span>
        </Error>
      )}
    </Container>
  );
}
