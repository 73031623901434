/* eslint-disable import/no-duplicates */
import type { TravelDocument } from '@hitechline/urbanonorte-types/dtos/manager/api';
import format from 'date-fns/format';
import ptBR from 'date-fns/locale/pt-BR';
import { useMemo } from 'react';

import { Divider } from '@/screen/components/ui/Divider';

import {
  Container,
  TicketInfo,
  Origin,
  Destination,
  GeneralInfo,
  TravelDate,
  CarInfo,
} from './styles';

interface TravelProps {
  travel: TravelDocument;
}

export function Travel({ travel }: TravelProps): JSX.Element {
  const dayOfWeekFormatting = useMemo(
    () =>
      format(new Date(travel.travel_time), 'EEEEEE', {
        locale: ptBR,
      }).toUpperCase(),
    [travel.travel_time],
  );

  const longLocalizedDate = useMemo(
    () => format(new Date(travel.travel_time), 'dd/MM/yyyy'),
    [travel.travel_time],
  );

  const formattedTime = useMemo(
    () => `${format(new Date(travel.travel_time), 'HH:mm')}H`,
    [travel.travel_time],
  );

  return (
    <Container key={travel.id}>
      <TicketInfo>
        <Origin>
          <span>Saindo de</span>
          <p>{travel.origin}</p>
        </Origin>

        <Destination>
          <span>Chegando em</span>
          <p>{travel.destination}</p>
        </Destination>
      </TicketInfo>

      <Divider background="--color-grey" />

      <GeneralInfo>
        <TravelDate>
          <div>
            <span className="date">{dayOfWeekFormatting}</span>
            <strong className="day">{longLocalizedDate}</strong>
          </div>

          <strong className="hour">{formattedTime}</strong>
        </TravelDate>

        {travel.driver.cars.map(car => (
          <CarInfo key={car.id}>
            <span>{car.model}</span>
            <p>{car.plaque}</p>
          </CarInfo>
        ))}
      </GeneralInfo>
    </Container>
  );
}
