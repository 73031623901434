import { css } from '@emotion/css';

import { BaseSelect, MainSelectProps } from './BaseSelect';

export type DarkSelectProps = MainSelectProps;

export function DarkSelect(props: DarkSelectProps): JSX.Element {
  return (
    <BaseSelect
      {...props}
      basedClassName={css`
        button > div {
          background-color: var(--color-medium-grey) !important;
        }
      `}
    />
  );
}
