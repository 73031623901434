/* eslint-disable react/jsx-no-useless-fragment */
import { mergeClassNames } from '@hitechline/reactools';
import { useMemo, useState } from 'react';
import { IconType } from 'react-icons';

import {
  Container,
  Menu,
  Category,
  CategoryTitle,
  CategoryList,
  CategoryOption,
  Tab,
} from './styles';

export interface TabNavigatorProps {
  categories: {
    name: string;
    tabs: {
      name: string;
      disabled?: boolean;
      icon?: IconType;
      component?: any;
    }[];
  }[];
}

export function TabNavigator({ categories }: TabNavigatorProps): JSX.Element {
  const [tabSelected, setTabSelected] = useState(categories[0].tabs[0].name);

  const categoriesElement = useMemo(
    () =>
      categories.map(({ name: categoryName, tabs }) => (
        <Category key={categoryName}>
          <CategoryTitle>{categoryName}</CategoryTitle>

          <CategoryList>
            {tabs.map(({ name, disabled, icon: Icon }) => (
              <CategoryOption
                key={name}
                type="button"
                disabled={disabled}
                className={mergeClassNames({
                  active: name === tabSelected,
                })}
                onClick={() => {
                  setTabSelected(name);
                }}
              >
                {Icon && (
                  <div className="icon">
                    <Icon size="30px" />
                  </div>
                )}

                {name}
              </CategoryOption>
            ))}
          </CategoryList>
        </Category>
      )),
    [categories, tabSelected],
  );

  const currentTabElement = useMemo(() => {
    const getTabs = categories.map(({ tabs }) => tabs).flatMap(tabs => tabs);
    const currentTab = getTabs.find(tab => tab.name === tabSelected);

    if (!currentTab) {
      return <></>;
    }

    const { component: Component } = currentTab;

    return <Component />;
  }, [categories, tabSelected]);

  return (
    <Container>
      <Menu>{categoriesElement}</Menu>

      <Tab>{currentTabElement}</Tab>
    </Container>
  );
}
