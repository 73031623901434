import { useContext } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import { statusOptions } from '@resources/data/options';
import { driverStatusUpdateSchema } from '@resources/schemas/driver/update';
import { Select } from '@screen/components/ui/Select';
import { Textarea } from '@screen/components/ui/Textarea';
import { ShowManageContext } from '@screen/pages/Driver/pages/Show/ShowManageContext';

import {
  Container,
  Form,
  FormMain,
  RegistrationSituation,
  ButtonContainer,
  SubmitButton,
} from './styles';

export function Status(): JSX.Element {
  const { driver, handleStatusEdit } = useContext(ShowManageContext);

  return (
    <Container>
      <Form
        initialData={driver.status}
        schema={driverStatusUpdateSchema}
        onSubmit={handleStatusEdit}
      >
        <FormMain>
          <RegistrationSituation>
            <Select
              name="registration_situation"
              label="Status"
              options={statusOptions}
              icon={AiOutlineInfoCircle}
            />
          </RegistrationSituation>

          <Textarea
            name="reason_of_punishment"
            label="Motivo"
            placeholder="Motivo da punição"
          />
        </FormMain>

        <ButtonContainer>
          <SubmitButton type="submit">Salvar</SubmitButton>
        </ButtonContainer>
      </Form>
    </Container>
  );
}
