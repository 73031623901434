import {
  useOutClick,
  useForceUpdate,
  mergeClassNames,
} from '@hitechline/reactools';
import { useField } from '@unform/core';
import { useState, useRef, useEffect, useCallback } from 'react';
import { FiX } from 'react-icons/fi';

import {
  Container,
  Content,
  InfoContent,
  ArrowIcon,
  Options,
  ValuesContainer,
  EmptyOptions,
  Error,
} from './styles';
import type { MultiSelectOption, BaseMultiSelectProps } from '../types';

export type MainMultiSelectProps = Omit<BaseMultiSelectProps, 'basedClassName'>;

export function BaseMultiSelect({
  name,
  title,
  options,
  className,
  basedClassName,
  icon: Icon,
  disabled,
  onChange,
  ...props
}: BaseMultiSelectProps): JSX.Element {
  const forceUpdate = useForceUpdate();
  const selectRef = useRef<MultiSelectOption[]>([]);

  const [optionsVisible, setOptionsVisible] = useState(false);

  const { error, fieldName, defaultValue, registerField } = useField(name);
  const {
    addListener,
    removeListener,
    ref: outClick,
  } = useOutClick<HTMLDivElement>();

  const openOptions = useCallback(() => {
    setTimeout(() => {
      setOptionsVisible(true);
    }, 0);
  }, []);

  const closeOptions = useCallback(() => {
    setOptionsVisible(false);
  }, []);

  const getOptionByValue = useCallback(
    (value: any) =>
      options.find(({ value: currentValue }) => currentValue === value),
    [options],
  );

  const setDefaultValue = useCallback(
    () => {
      if (Array.isArray(defaultValue)) {
        defaultValue.map(value => setValue(value));
      }

      setValue(defaultValue);
    },
    [defaultValue], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const setValue = useCallback(
    (value: any, close = false) => {
      const option = getOptionByValue(value);

      if (
        !option ||
        selectRef.current.some(
          selectValue => selectValue.value === option.value,
        )
      ) {
        return;
      }

      selectRef.current = [...selectRef.current, option];

      if (close) {
        closeOptions();
      }

      // Call before, because "onChange" can contains unhandled error
      forceUpdate();

      if (typeof onChange === 'function') {
        onChange(selectRef.current);
      }
    },
    [selectRef, onChange, forceUpdate, closeOptions, getOptionByValue],
  );

  const removeSelectedOption = useCallback(
    (value: any) => {
      selectRef.current = selectRef.current.filter(
        option => option.value !== value,
      );

      forceUpdate();
    },
    [forceUpdate],
  );

  const renderValues = (values: MultiSelectOption[]): JSX.Element => (
    <ValuesContainer>
      {values.map(value => (
        <div key={value.value}>
          <span>{value.label}</span>

          <button
            type="button"
            onClick={() => removeSelectedOption(value.value)}
          >
            <FiX />
          </button>
        </div>
      ))}
    </ValuesContainer>
  );

  const renderDefaultValue = (): string | JSX.Element => {
    if (Array.isArray(defaultValue)) {
      const values = defaultValue
        .map(value => getOptionByValue(value))
        .filter(Boolean) as MultiSelectOption[];

      return values.length ? renderValues(values) : title;
    }

    return title;
  };

  const renderTitle = (() => {
    if (selectRef.current.length) {
      return renderValues(selectRef.current);
    }

    return defaultValue ? renderDefaultValue() : title;
  })();

  const optionsFiltered = options.filter(
    option =>
      !selectRef.current.some(
        selectOption => selectOption.value === option.value,
      ),
  );

  useEffect(() => {
    setDefaultValue();
  }, [setDefaultValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef,
      getValue: () => selectRef.current.map(({ value }) => value),
      clearValue: () => {
        selectRef.current.splice(0);
      },
    });
  }, [selectRef, fieldName, registerField]);

  useEffect(() => {
    addListener(closeOptions);

    return () => {
      removeListener(closeOptions);
    };
  }, [closeOptions, addListener, removeListener]);

  return (
    <Container
      {...props}
      ref={outClick}
      className={mergeClassNames(basedClassName, className, {
        'disabled': Boolean(disabled),
        'error': Boolean(error),
        'with-icon': Boolean(Icon),
      })}
      data-disabled={disabled}
    >
      <Content
        type="button"
        disabled={disabled}
        onClick={optionsVisible ? closeOptions : openOptions}
      >
        {Icon && (
          <div className="icon">
            <Icon />
          </div>
        )}

        <InfoContent withIcon={Boolean(Icon)}>
          {renderTitle}

          <ArrowIcon open={optionsVisible} />
        </InfoContent>
      </Content>

      {error && (
        <Error>
          <span>{error}</span>
        </Error>
      )}

      {optionsVisible && (
        <Options>
          {optionsFiltered.length ? (
            optionsFiltered.map(({ value, label: text }) => (
              <button
                key={value}
                type="button"
                onClick={() => setValue(value, true)}
              >
                {text}
              </button>
            ))
          ) : (
            <EmptyOptions>Nada por aqui :)</EmptyOptions>
          )}
        </Options>
      )}
    </Container>
  );
}
