import styled from '@emotion/styled';

import { Form as FormDefault } from '@/screen/components/forward/Form';
import { Button } from '@/screen/components/ui/Button';

export const Container = styled.div``;

export const Form = styled(FormDefault)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const FormMain = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const RegistrationSituation = styled.div`
  max-width: 350px;
`;

export const ButtonContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;
`;

export const SubmitButton = styled(Button)`
  max-width: 200px;
`;
