import styled from '@emotion/styled';

import { Form } from '@/screen/components/forward/Form';

export const Container = styled.tr``;

export const Row = styled.td``;

export const RowContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

export const Label = styled.span`
  max-width: 150px;
  height: 30px;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  color: var(--color-white);

  border-radius: 15px;
`;

export const IconButton = styled.button`
  width: 35px;
  height: 35px;

  border-radius: 10px;
  background: var(--color-light-favorite);

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    cursor: pointer;
  }
`;

export const UpdateCarModalContainer = styled.div`
  --padding: 20px;

  display: flex;
  width: 100%;
  max-width: 712px;
  min-height: 280px;
  max-height: calc(100vh - 40px);
  overflow: hidden;

  margin: 0 auto;

  align-items: center;
  flex-direction: column;

  border-radius: 28px;

  box-shadow: var(--box-shadow);
  background: var(--color-white);

  color: var(--color-black);

  @media (max-width: 712px) {
    height: 100vh;
    max-height: 100%;
    border-radius: 0;
  }
`;

export const UpdateCarModalHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 20px var(--padding);

  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  p {
    font-size: 2.2rem;
    font-weight: 700;
  }
`;

export const UpdateCarModalContent = styled(Form)`
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  width: 100%;
  height: 100%;

  gap: 16px;

  padding: var(--padding);

  overflow-y: auto;
`;

export const CloseButton = styled.button`
  --size: 38px;

  display: flex;

  width: var(--size);
  height: var(--size);

  flex-shrink: 0;

  margin-left: auto;

  align-items: center;
  justify-content: center;

  background-color: rgba(var(--color-error-rgb), 0.2);
  border-radius: 999px;

  svg {
    width: 2.4rem;
    height: auto;
  }
`;
