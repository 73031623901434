import styled from '@emotion/styled';

export const Container = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 280px auto;
`;

export const Menu = styled.div`
  width: 100%;

  padding: 30px 0;

  display: flex;
  flex-direction: column;
  gap: 10px;

  border-radius: 10px;
`;

export const Category = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const CategoryTitle = styled.span`
  padding: 0 25px;

  display: block;

  font-size: 12px;
  font-weight: 600;
  color: var(--color-grey);
  text-transform: uppercase;
`;

export const CategoryList = styled.div``;

export const CategoryOption = styled.button`
  width: 100%;
  height: 60px;
  padding: 25px;

  display: flex;
  align-items: center;
  text-align: center;
  gap: 10px;

  font-size: 18px;
  font-weight: normal;
  color: var(--color-strong-grey);

  transition: all 0.4s;

  &:disabled {
    cursor: not-allowed;
    user-select: none;
    opacity: 0.5;

    &:hover {
      color: var(--color-strong-grey);

      background-color: transparent;

      .icon svg {
        color: var(--color-strong-grey);
      }
    }
  }

  &.active {
    color: var(--color-favorite);

    .icon svg {
      color: var(--color-favorite);
    }
  }

  &:hover {
    color: var(--color-light-favorite);

    .icon svg {
      color: var(--color-light-favorite);
    }
  }

  .icon svg {
    color: var(--color-strong-grey);

    transition: all 0.4s;
  }
`;

export const Tab = styled.div``;
