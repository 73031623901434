import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 2rem;

  border: none;
  outline: none;
  border-radius: var(--wai-main-border-radius);
  background-color: var(--color-white);
`;

export const TicketInfo = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  svg {
    flex-shrink: 0;
  }

  span {
    font-size: 17px;
    font-weight: 400;
    color: var(--color-grey);
  }

  p {
    font-size: 1.8rem;
    font-weight: 500;
    color: var(--color-black);
  }

  @media (max-width: 400px) {
    span,
    p {
      font-size: 10px;
    }
  }
`;

export const Origin = styled.div``;

export const Destination = styled.div`
  span,
  p {
    text-align: right;
  }
`;

export const GeneralInfo = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TravelDate = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  div {
    display: flex;
    gap: 0.5rem;
  }

  strong,
  span {
    font-size: 19px;
    line-height: 20px;

    color: var(--color-favorite);
  }

  .day {
    font-weight: 500;
  }

  .date {
    font-weight: 300;
  }

  .hour {
    font-weight: 700;
    color: var(--color-grey);
  }

  @media (max-width: 400px) {
    strong,
    span {
      font-size: 15px;
      font-weight: 400;
    }
  }
`;

export const CarInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  span,
  p {
    text-align: right;
  }

  p {
    color: var(--color-grey);
  }

  @media (max-width: 400px) {
    span,
    p {
      display: flex;
      flex-direction: column;
      font-size: 12px;
    }
  }
`;
