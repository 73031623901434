import styled from '@emotion/styled';
import type { DriverStatusRegistrationSituationType } from '@hitechline/urbanonorte-types/model/urbanolog';

interface StatusLabelProps {
  status: DriverStatusRegistrationSituationType;
}

const getStatusColor = (
  status: DriverStatusRegistrationSituationType,
): string => {
  switch (status) {
    case 'approved': {
      return 'var(--color-light-green)';
    }

    case 'pending': {
      return 'var(--color-light-yellow)';
    }

    case 'denied': {
      return 'var(--color-error)';
    }

    default: {
      return 'var(--color-strong-grey)';
    }
  }
};

export const Container = styled.tr``;

export const Row = styled.td``;

export const RowContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

export const Label = styled.span<StatusLabelProps>`
  max-width: 150px;
  height: 30px;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  color: var(--color-white);

  border-radius: 10px;
  background-color: ${({ status }) => getStatusColor(status)};
`;

export const IconButton = styled.button`
  width: 30px;
  height: 30px;

  border-radius: 10px;
  background: var(--color-light-favorite);

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    cursor: pointer;
  }
`;
