import type { DriverDocument } from '@hitechline/urbanonorte-types/dtos/manager/api';
import { useCallback, useContext, useRef } from 'react';
import { FiX } from 'react-icons/fi';
import { RiEditBoxFill } from 'react-icons/ri';
import { toast } from 'react-toastify';

import { api } from '@/modules/services/api';
import { driverCarUpdateSchema } from '@/resources/schemas/driver/update';
import { Modal, ModalHandles } from '@/screen/components/forward/Modal';
import { Button } from '@/screen/components/ui/Button';
import { DarkInput } from '@/screen/components/ui/Input';
import { DarkSelect, SelectOption } from '@/screen/components/ui/Select';

import {
  Container,
  Row,
  RowContent,
  IconButton,
  UpdateCarModalContainer,
  UpdateCarModalHeader,
  CloseButton,
  UpdateCarModalContent,
} from './styles';
import { ShowManageContext } from '../../ShowManageContext';

interface CarTableRowProps {
  car: DriverDocument['cars'][0];
}

const pendingSelectOptions: SelectOption[] = [
  {
    label: 'Sim',
    value: true,
  },
  {
    label: 'Não',
    value: false,
  },
];

export function CarTableRow({ car }: CarTableRowProps): JSX.Element {
  const modalRef = useRef<ModalHandles>(null);

  const { driver, retrieveDriver } = useContext(ShowManageContext);

  const openModal = useCallback(() => {
    modalRef.current?.open();
  }, []);

  const closeModal = useCallback(() => {
    modalRef.current?.close();
  }, []);

  const handleCarUpdate = useCallback(
    async data => {
      try {
        await api.put(`/drivers/${driver.id}/cars/${car.id}`, data);

        await retrieveDriver().catch(() => null);

        toast.success('veículo atualizado com sucesso.');
      } catch {
        toast.error('Ocorreu um erro ao atualizar o veículo.');
      } finally {
        closeModal();
      }
    },
    [car.id, driver.id, retrieveDriver, closeModal],
  );

  return (
    <>
      <Container>
        <Row>{car.plaque}</Row>
        <Row>{car.brand}</Row>
        <Row>{car.model}</Row>
        <Row>{car.pending ? 'Sim' : 'Não'}</Row>
        <Row>
          <RowContent>
            <IconButton onClick={openModal}>
              <RiEditBoxFill size="25px" color="var(--color-white)" />
            </IconButton>
          </RowContent>
        </Row>
      </Container>

      <Modal ref={modalRef}>
        <UpdateCarModalContainer>
          <UpdateCarModalHeader>
            <p>Atualizar Carro</p>

            <CloseButton type="button" onClick={closeModal}>
              <FiX size="2.5rem" color="var(--color-white)" />
            </CloseButton>
          </UpdateCarModalHeader>

          <UpdateCarModalContent
            className="scrollbar-custom"
            onSubmit={handleCarUpdate}
            initialData={car}
            schema={driverCarUpdateSchema}
          >
            <DarkSelect
              label="Pendente"
              name="pending"
              options={pendingSelectOptions}
            />

            <DarkInput
              label="Placa"
              name="plaque"
              placeholder="Placa do veículo"
            />

            <DarkInput
              label="Marca"
              name="brand"
              placeholder="Marca do veículo"
            />

            <DarkInput
              label="Modelo"
              name="model"
              placeholder="Modelo do veículo"
            />

            <DarkInput label="Cor" name="color" placeholder="Cor do veículo" />

            <DarkInput label="Ano" name="year" placeholder="Ano do veículo" />

            <DarkInput
              label="Ano do modelo"
              name="model_year"
              placeholder="Ano do modelo do veículo"
            />

            <DarkInput
              label="Chassi"
              name="chassi"
              placeholder="Chassi do veículo"
            />

            <DarkInput
              label="Renavam"
              name="renavam"
              placeholder="Renavam do veículo"
            />

            <Button type="submit">Salvar</Button>
          </UpdateCarModalContent>
        </UpdateCarModalContainer>
      </Modal>
    </>
  );
}
