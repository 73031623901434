import type {
  DriverDocument,
  DriverUpdateData,
  DriverAddressUpdateData,
  DriverStatusUpdateData,
} from '@hitechline/urbanonorte-types/dtos/manager/api';
import { useState, useCallback, useEffect } from 'react';
import {
  AiOutlineUser,
  AiOutlineCar,
  AiOutlineHistory,
  AiOutlineSchedule,
  AiOutlineUnorderedList,
} from 'react-icons/ai';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { IoMdSwitch } from 'react-icons/io';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { api } from '@modules/services/api';
import { apply } from '@resources/cases/apply';
import { Logged } from '@resources/cases/Logged';
import { StandardLoader } from '@screen/components/ui/StandardLoader';
import { TabNavigator } from '@screen/components/ui/TabNavigator';
import { DefaultLayout } from '@screen/layouts/DefaultLayout';

import {
  PersonalData,
  Address,
  Vehicles,
  Status,
  Schedules,
  Historic,
  LastTravels,
} from './sections';
import { ShowManageContext } from './ShowManageContext';
import { Container, Content } from './styles';

export const DriverShow = apply(
  (): JSX.Element => {
    const { push } = useHistory();
    const { id } = useParams<{ id: string }>();

    const [loading, updateLoading] = useState(true);
    const [retrieving, updateRetrieving] = useState(false);

    const [driver, updateDriver] = useState<DriverDocument | null>(null);

    const loadDriver = useCallback(async () => {
      try {
        const { data } = await api.get<DriverDocument>(`drivers/${id}`);

        updateDriver(data);
        updateLoading(false);
      } catch {
        push('/drivers');
      }
    }, [id, push]);

    const retrieveDriver = useCallback(async () => {
      try {
        updateRetrieving(true);

        const { data } = await api.get<DriverDocument>(`drivers/${id}`);

        updateDriver(data);
        updateRetrieving(false);
      } catch {
        // nothing
      }
    }, [id]);

    const handlePersonalDataEdit = useCallback(
      async (data: DriverUpdateData) => {
        try {
          await api.put(`drivers/${id}`, {
            ...data,
            cnh_type: (data.cnh_type as unknown as string[]).join('/'),
          });

          await retrieveDriver();

          toast.success('Dados pessoais atualizados com sucesso.');
        } catch {
          toast.error('Ocorreu um erro!');
        }
      },
      [id, retrieveDriver],
    );

    const handleAddressEdit = useCallback(
      async (data: DriverAddressUpdateData) => {
        try {
          await api.put(`drivers/${id}/address`, data);

          await retrieveDriver();

          toast.success('Endereço atualizado com sucesso.');
        } catch {
          toast.error('Ocorreu um erro!');
        }
      },
      [id, retrieveDriver],
    );

    const handleStatusEdit = useCallback(
      async (data: DriverStatusUpdateData) => {
        try {
          await api.put(`drivers/${id}/status`, data);

          await retrieveDriver();

          toast.success('Status atualizado com sucesso.');
        } catch {
          toast.error('Ocorreu um erro!');
        }
      },
      [id, retrieveDriver],
    );

    useEffect(() => {
      loadDriver();
    }, [loadDriver]);

    if (loading || !driver) {
      return <StandardLoader />;
    }

    const categories = [
      {
        name: 'Geral',
        tabs: [
          {
            name: 'Dados Pessoais',
            icon: AiOutlineUser,
            component: PersonalData,
          },
          {
            name: 'Endereço',
            icon: HiOutlineLocationMarker,
            component: Address,
          },
          {
            name: 'Veículos',
            icon: AiOutlineCar,
            component: Vehicles,
          },
        ],
      },
      {
        name: 'Detalhes',
        tabs: [
          {
            name: 'Últimas Viagens',
            icon: AiOutlineUnorderedList,
            component: LastTravels,
          },
          {
            name: 'Viagens Agendadas',
            icon: AiOutlineSchedule,
            component: Schedules,
          },
          {
            name: 'Histórico de Viagens',
            icon: AiOutlineHistory,
            component: Historic,
          },
        ],
      },
      {
        name: 'Administração',
        tabs: [
          {
            name: 'Status',
            icon: IoMdSwitch,
            component: Status,
          },
        ],
      },
    ];

    return (
      <ShowManageContext.Provider
        value={{
          retrieving,
          driver,
          retrieveDriver,
          handlePersonalDataEdit,
          handleAddressEdit,
          handleStatusEdit,
        }}
      >
        <Container className="bowl-content padding-y">
          <Content>
            <TabNavigator categories={categories} />
          </Content>
        </Container>
      </ShowManageContext.Provider>
    );
  },
  {
    layout: DefaultLayout,
    cases: [Logged],
  },
);
