import type { MultiSelectOption } from '@/screen/components/ui/MultiSelect';
import type { SelectOption } from '@/screen/components/ui/Select';

export const cnhTypeOptions: MultiSelectOption[] = [
  'A',
  'B',
  'C',
  'D',
  'E',
].map<MultiSelectOption>(type => ({
  label: type,
  value: type,
}));

export const statusOptions: SelectOption[] = [
  {
    label: 'Aprovado',
    value: 'approved',
  },
  {
    label: 'Pendente',
    value: 'pending',
  },
  {
    label: 'Negado',
    value: 'denied',
  },
];

export const genderOptions: SelectOption[] = [
  {
    label: 'Masculino',
    value: 'male',
  },
  {
    label: 'Feminino',
    value: 'female',
  },
  {
    label: 'Não Binário',
    value: 'non_binary',
  },
];
