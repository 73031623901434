import { Spinner } from '@/screen/components/ui/Spinner';

import { Container } from './styles';

export function StandardLoader(): JSX.Element {
  return (
    <Container>
      <Spinner />
    </Container>
  );
}
