import { AiOutlineUser } from 'react-icons/ai';
import { FaConciergeBell } from 'react-icons/fa';

import { apply } from '@resources/cases/apply';
import { Logged } from '@resources/cases/Logged';
import { TabNavigator } from '@screen/components/ui/TabNavigator';
import { DefaultLayout } from '@screen/layouts/DefaultLayout';

import { PersonalData, Logs } from './components/Sections';
import { Container, Content } from './styles';

export const User = apply(
  (): JSX.Element => {
    const categories = [
      {
        name: 'Geral',
        tabs: [
          {
            name: 'Dados Pessoais',
            icon: AiOutlineUser,
            component: PersonalData,
          },
          {
            name: 'Registro de auditoria',
            icon: FaConciergeBell,
            component: Logs,
          },
        ],
      },
    ];

    return (
      <Container className="bowl-content padding-y">
        <Content>
          <TabNavigator categories={categories} />
        </Content>
      </Container>
    );
  },
  {
    layout: DefaultLayout,
    cases: [Logged],
  },
);
