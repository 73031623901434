import styled from '@emotion/styled';

export const Container = styled.button`
  max-width: var(--wai-max-width);
  width: 100%;
  height: 50px;
  padding: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  font-size: 16px;
  font-weight: 700;
  color: var(--color-white);

  cursor: pointer;
  border-radius: 10px;
  transition: background 0.4s;
  background-color: var(--color-light-favorite);

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  svg {
    flex-shrink: 0;
  }
`;
