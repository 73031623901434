import styled from '@emotion/styled';

export const Container = styled.footer`
  padding: 20px;

  background-color: var(--color-black);
`;

export const Content = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 1.5rem;
    color: var(--color-white);
  }

  span {
    display: block;

    font-size: 1.2rem;
    color: var(--color-strong-grey);
  }
`;
