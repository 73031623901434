import { useContext } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { BiMedal } from 'react-icons/bi';
import {
  FiPhone,
  FiUserCheck,
  FiBook,
  FiCalendar,
  FiBookOpen,
} from 'react-icons/fi';
import { ImStarEmpty, ImStarFull, ImStarHalf } from 'react-icons/im';
import { IoMaleFemaleOutline } from 'react-icons/io5';
import { MdMailOutline } from 'react-icons/md';

import { genderOptions, cnhTypeOptions } from '@resources/data/options';
import { driverUpdateSchema } from '@resources/schemas/driver/update';
import { Avatar } from '@screen/components/ui/Avatar';
import { Input } from '@screen/components/ui/Input';
import { MultiSelect } from '@screen/components/ui/MultiSelect';
import { Select } from '@screen/components/ui/Select';
import { ShowManageContext } from '@screen/pages/Driver/pages/Show/ShowManageContext';

import {
  Container,
  FormMain,
  Form,
  AvatarContainer,
  Avaliation,
  AvaliationContent,
  Email,
  Phone,
  FullName,
  Document,
  Birthdate,
  Gender,
  Cnh,
  CnhType,
  ButtonContainer,
  SubmitButton,
} from './styles';

export function PersonalData(): JSX.Element {
  const { driver, handlePersonalDataEdit } = useContext(ShowManageContext);

  return (
    <Container>
      <Form
        initialData={{
          ...driver,
          cnh_type: driver.cnh_type.split('/'),
        }}
        schema={driverUpdateSchema}
        onSubmit={handlePersonalDataEdit}
      >
        <FormMain>
          <AvatarContainer>
            <Avatar
              name={driver.full_name}
              src={driver?.avatar as string}
              size="180"
              color="var(--color-grey)"
              round
            />
          </AvatarContainer>

          <Avaliation>
            <div className="icon">
              <BiMedal />
            </div>

            <AvaliationContent>
              <span>Avaliação</span>

              <div className="stars">
                <ImStarFull size="14px" color="var(--color-light-favorite)" />
                <ImStarFull size="14px" color="var(--color-light-favorite)" />
                <ImStarFull size="14px" color="var(--color-light-favorite)" />
                <ImStarHalf size="14px" color="var(--color-light-favorite)" />
                <ImStarEmpty size="14px" color="var(--color-light-favorite)" />
              </div>
            </AvaliationContent>
          </Avaliation>

          <Email>
            <Input
              name="email"
              label="E-mail"
              placeholder="example@urbanonorte.com.br"
              icon={MdMailOutline}
            />
          </Email>

          <Phone>
            <Input
              name="phone"
              label="Telefone"
              mask="(99) \9 9999-9999" // eslint-disable-line no-nonoctal-decimal-escape
              placeholder="Telefone"
              icon={FiPhone}
            />
          </Phone>

          <FullName>
            <Input
              name="full_name"
              label="Nome"
              placeholder="Seu Nome"
              disabled
              icon={AiOutlineUser}
            />
          </FullName>

          <Document>
            <Input
              name="document"
              label="CPF"
              mask="999.999.999-99"
              placeholder="CPF"
              icon={FiUserCheck}
            />
          </Document>

          <Birthdate>
            <Input
              name="birthdate"
              label="Data de nascimento"
              mask="99/99/9999"
              placeholder="dd/mm/yyyy"
              icon={FiCalendar}
            />
          </Birthdate>

          <Gender>
            <Select
              name="gender"
              label="Gênero"
              options={genderOptions}
              disabled
              icon={IoMaleFemaleOutline}
            />
          </Gender>

          <Cnh>
            <Input
              name="cnh"
              label="CNH"
              mask="99999999999"
              placeholder="CNH"
              disabled
              icon={FiBook}
            />
          </Cnh>

          <CnhType>
            <MultiSelect
              name="cnh_type"
              title="Tipo da CNH"
              options={cnhTypeOptions}
              disabled
              icon={FiBookOpen}
            />
          </CnhType>
        </FormMain>

        <ButtonContainer>
          <SubmitButton type="submit">Salvar</SubmitButton>
        </ButtonContainer>
      </Form>
    </Container>
  );
}
