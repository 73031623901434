import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  > label {
    display: block;
    width: max-content;
    margin-bottom: 3px;
  }

  &.disabled {
    opacity: 0.5;

    &,
    textarea {
      cursor: not-allowed;
      user-select: none;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  min-height: 150px;
  padding: 20px;

  position: relative;

  display: flex;
  flex-direction: column;
  gap: 4px;

  font-size: 16px;

  overflow: hidden;
  border-radius: var(--wai-main-border-radius);
  background-color: var(--textarea-background);

  > label {
    color: var(--label-color);
  }
`;

export const TextareaElement = styled.textarea`
  width: 100%;

  resize: none;

  color: var(--textarea-color);

  border: none;
  outline: none;
  background-color: transparent;

  &::placeholder {
    color: var(--textarea-placeholder);
  }
`;

export const Error = styled.div`
  margin-top: 10px;

  font-size: 12px;
  color: var(--color-error);

  text-transform: uppercase;

  > span {
    display: block;

    text-align: left;
  }
`;
