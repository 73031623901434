import Chart from 'react-apexcharts';

import { Logged } from '@/resources/cases/Logged';
import { apply } from '@resources/cases/apply';
import { DefaultLayout } from '@screen/layouts/DefaultLayout';

import { Container, Box } from './styles';

const options = {
  chart: {
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
    foreColor: 'var(--color-grey)',
  },
  grid: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    enabled: false,
  },
  xaxis: {
    type: 'datetime',
    axisBorder: {
      color: 'var(--color-grey)',
    },
    axisTicks: {
      color: 'var(--color-grey)',
    },
    categories: [
      '2022-03-18T00:00:00.000Z',
      '2022-03-19T00:00:00.000Z',
      '2022-03-20T00:00:00.000Z',
      '2022-03-21T00:00:00.000Z',
      '2022-03-22T00:00:00.000Z',
      '2022-03-23T00:00:00.000Z',
      '2022-03-24T00:00:00.000Z',
      '2022-03-25T00:00:00.000Z',
    ],
  },
  fill: {
    opacity: 0.3,
    type: 'gradient',
    gradient: {
      shade: 'dark',
      opacityFrom: 0.7,
      opacityTo: 0.3,
    },
  },
};
const series = [
  {
    name: 'series1',
    color: 'var(--color-favorite)',
    data: [5, 10, 20, 40, 60, 80, 40],
  },
];

export const Home = apply(
  (): JSX.Element => {
    return (
      <Container className="bowl-content padding-y">
        <Box>
          <span>Motoristas</span>
          <Chart
            type="area"
            height={160}
            options={options as any}
            series={series}
          />
        </Box>
        <Box>
          <span>Cessionários</span>
          <Chart
            type="area"
            height={160}
            options={options as any}
            series={series}
          />
        </Box>
        <Box>
          <span>Vendas</span>
          <Chart
            type="area"
            height={160}
            options={options as any}
            series={series}
          />
        </Box>
      </Container>
    );
  },
  {
    layout: DefaultLayout,
    cases: [Logged],
  },
);
