import styled from '@emotion/styled';

import { Form as DefaultForm } from '@screen/components/forward/Form';

export const Container = styled.div`
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  background-image: url('assets/city.png');
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Form = styled(DefaultForm)`
  max-width: 350px;
  height: auto;
  margin: auto;
  padding: 50px;
  background-color: var(--color-black);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  h3 {
    font-size: 20px;
    color: #fff;
    font-weight: 500;
    margin-bottom: 2rem;
    text-align: center;
  }

  button {
    margin-top: 1.5rem;

    width: 180px;

    border-radius: 50px;
  }
`;
