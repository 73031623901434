import { SessionLoginData } from '@hitechline/urbanonorte-types/dtos/franchisee/api';
import { useCallback } from 'react';
import { BiUserCircle } from 'react-icons/bi';
import { GoKey } from 'react-icons/go';

import { apply } from '@resources/cases/apply';
import { Logged } from '@resources/cases/Logged';
import { useAuth } from '@resources/hooks/useAuth';
import { loginSchema } from '@resources/schemas/login';
import { Button } from '@screen/components/ui/Button';
import { DarkInput } from '@screen/components/ui/Input';

import { Container, Form } from './styles';

export const Login = apply(
  (): JSX.Element => {
    const { signIn } = useAuth();

    const handleSubmit = useCallback(
      ({ email, password }: SessionLoginData) =>
        signIn({
          email,
          password,
        }),
      [signIn],
    );

    return (
      <Container>
        <Form schema={loginSchema} onSubmit={handleSubmit}>
          <h3>Login</h3>

          <DarkInput name="email" placeholder="E-mail" icon={BiUserCircle} />

          <DarkInput
            name="password"
            type="password"
            placeholder="Senha"
            icon={GoKey}
          />

          <Button type="submit">Acessar</Button>
        </Form>
      </Container>
    );
  },
  {
    cases: [Logged],
  },
);
