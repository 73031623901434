import type { DriverDocument } from '@hitechline/urbanonorte-types/dtos/manager/api';
import type { DriverStatusRegistrationSituationType } from '@hitechline/urbanonorte-types/model/urbanolog';
import { useCallback } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { Container, Row, RowContent, Label, IconButton } from './styles';

interface DriverTableRowProps {
  driver: DriverDocument;
}

const getStatus = (status: DriverStatusRegistrationSituationType): string => {
  switch (status) {
    case 'approved': {
      return 'Aprovado';
    }

    case 'pending': {
      return 'Pendente';
    }

    case 'denied': {
      return 'Negado';
    }

    default: {
      return '';
    }
  }
};

export function DriverTableRow({
  driver: {
    id,
    full_name,
    document,
    email,
    status: { registration_situation },
  },
}: DriverTableRowProps): JSX.Element {
  const { push } = useHistory();

  const redirectToDriverPage = useCallback(
    (driverId: string) => {
      push(`/drivers/${driverId}`);
    },
    [push],
  );
  return (
    <Container>
      <Row>{full_name}</Row>
      <Row>{document}</Row>
      <Row>{email}</Row>
      <Row>
        <RowContent>
          <Label status={registration_situation}>
            {getStatus(registration_situation)}
          </Label>
        </RowContent>
      </Row>
      <Row>
        <RowContent>
          <IconButton onClick={() => redirectToDriverPage(id)}>
            <FiChevronRight size="40px" color="var(--color-white)" />
          </IconButton>
        </RowContent>
      </Row>
    </Container>
  );
}
