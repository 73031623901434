import type {
  DriverUpdateData,
  DriverAddressUpdateData,
  // DriverStatusUpdateData,
  DriverCarUpdateData,
} from '@hitechline/urbanonorte-types/dtos/manager/api';
import type { YupObjectSchemaType } from '@hitechline/urbanonorte-types/modules/yup';
import { object, string, number, array, mixed, boolean } from 'yup';

type DriverUpdateSchemaType = YupObjectSchemaType<DriverUpdateData>;

type DriverAddressUpdateSchemaType =
  YupObjectSchemaType<DriverAddressUpdateData>;

const cnhTypes = ['A', 'B', 'C', 'D', 'E'];
const genders = ['male', 'female', 'non_binary'];
const status = ['pending', 'approved', 'denied'];

export const driverUpdateSchema = object<DriverUpdateSchemaType>({
  full_name: string()
    .required('Insira um nome.')
    .trim()
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/g,
      'Insira caracteres válidos.',
    )
    .matches(
      /^(?:([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)) (?:([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*))$/g,
      'Insira seu nome completo.',
    ),
  email: string().email().required('Insira seu e-mail.').trim().lowercase(),
  phone: string()
    .required('Insira um número de telefone.')
    .matches(/\([0-9]{2}\) 9 [0-9]{4}-[0-9]{4}/, 'Insira um número válido.'),
  document: string()
    .required('Insira um CPF.')
    .matches(/[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}/, 'Insira um CPF válido.'),
  cnh: string().required('Insira o número da CNH.'),
  cnh_type: <any>array()
    .required('Selecione o tipo da CNH.')
    .min(1, 'Selecione o tipo da CNH.')
    .test({
      message: 'Insira tipos válidos.',
      test: values => {
        if (!values) {
          return false;
        }

        return values.every(value => cnhTypes.includes(value));
      },
    }),
  gender: <any>(
    mixed()
      .defined()
      .required('Insira um gênero.')
      .oneOf(Object.values(genders), 'Insira um gênero válido.')
  ),
  birthdate: string()
    .required('Insira uma data de nascimento.')
    .matches(/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/, 'Insira uma data válida.'),
});

export const driverAddressUpdateSchema = object<DriverAddressUpdateSchemaType>({
  street: string().required('Informe a rua.'),
  state: string().default('Mato Grosso do Sul'),
  number: number()
    .required('Informe o número.')
    .transform((currentValue, originalValue) => {
      if (typeof originalValue === 'string' && originalValue === '') {
        return undefined;
      }

      return currentValue;
    }),
  neighborhood: string().required('Informe o bairro.'),
  zip_code: string().required('Informe o CEP do local.'),
  city: string().required('Informe a cidade.'),
});

export const driverStatusUpdateSchema = object({
  registration_situation: <any>(
    mixed()
      .defined()
      .required('Insira um status.')
      .oneOf(Object.values(status), 'Insira um status válido.')
  ),
  reason_of_punishment: string(),
});

type DriverCarUpdateSchemaType = YupObjectSchemaType<DriverCarUpdateData>;

export const driverCarUpdateSchema = object<DriverCarUpdateSchemaType>({
  brand: string().required('Insira a marca do veículo.'),
  color: string().required('Insira a cor do veículo.'),
  model: string().required('Insira o modelo do veículo.'),
  model_year: string()
    .matches(/^[0-9]{4}$/, 'Insira um ano válido.')
    .required('Insira o ano do modelo do veículo.'),
  pending: boolean().required('Preencha esse campo.'),
  plaque: string()
    .matches(/^[A-Z0-9]{4,}$/g, 'Insira uma placa válida.')
    .required('Insira a placa do veículo.'),
  chassi: string().required('Insira o chassi do veículo.'),
  renavam: string().required('Insira o renavam do veículo.'),
  year: string()
    .matches(/^[0-9]{4}$/, 'Insira um ano válido.')
    .required('Insira o ano do veículo.'),
});
