/* eslint-disable  no-nested-ternary */
import { useAsync } from '@hitechline/reactools';
import type {
  GetDriverTravelsHttpData,
  TravelDocument,
} from '@hitechline/urbanonorte-types/dtos/manager/api';
import { useCallback, useEffect, useState } from 'react';
import { HiOutlineAdjustments } from 'react-icons/hi';
import { IoIosAlert } from 'react-icons/io';
import { useParams } from 'react-router-dom';

import { api } from '@/modules/services/api';
import { Pagination } from '@/screen/components/ui/Pagination';
import { Spinner } from '@/screen/components/ui/Spinner';

import {
  Container,
  Header,
  FilterButton,
  Input,
  Main,
  Footer,
  StatusMessage,
} from './styles';
import { Travel } from './Travel';

const itemsPerPage = 12;

export function LastTravels(): JSX.Element {
  const { id } = useParams<{ id: string }>();

  const [inReset, setInReset] = useState(false);

  const [query, setQuery] = useState('');

  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [travels, setTravels] = useState<Omit<TravelDocument, 'passengers'>[]>(
    [],
  );

  const fetchTravels = useCallback(
    async (
      page: number,
      search?: string,
    ): Promise<GetDriverTravelsHttpData> => {
      const { data: apiData } = await api.get<GetDriverTravelsHttpData>(
        `drivers/${id}/travels`,
        {
          params: { search, page, itemsPerPage },
        },
      );

      return apiData;
    },
    [id],
  );

  const { loading } = useAsync(async () => {
    const apiData = await fetchTravels(1);

    setPages(apiData.pages);
    setTravels(apiData.items);
  });

  const filterTravels = useCallback((value: string) => {
    setQuery(value);
  }, []);

  const reset = useCallback(async () => {
    try {
      setInReset(true);
      setCurrentPage(1);
      setTravels([]);

      const apiData = await fetchTravels(1, query);

      setPages(apiData.pages);
      setTravels(apiData.items);
    } finally {
      setInReset(false);
    }
  }, [fetchTravels, query]);

  const pageChange = useCallback(
    async (page: number) => {
      const data = await fetchTravels(page);

      setCurrentPage(page);
      setTravels(data.items);
    },
    [fetchTravels],
  );

  const nextPage = useCallback(() => {
    if (currentPage === pages) {
      return;
    }

    pageChange(currentPage + 1);
  }, [currentPage, pages, pageChange]);

  const previousPage = useCallback(() => {
    if (currentPage === 1) {
      return;
    }

    pageChange(currentPage - 1);
  }, [currentPage, pageChange]);

  useEffect(() => {
    if (loading) {
      return;
    }

    reset();
  }, [loading, reset]);

  return (
    <Container>
      <Header>
        <FilterButton disabled={!travels.length && !query}>
          <HiOutlineAdjustments size={25} color="white" />
        </FilterButton>

        <Input
          placeholder="Pesquisar..."
          disabled={!travels.length && !query}
          onChange={e => filterTravels(e.target.value)}
        />
      </Header>

      {loading || inReset ? (
        <StatusMessage>
          <Spinner size="60px" />
        </StatusMessage>
      ) : travels.length > 0 ? (
        <>
          <Main>
            {travels.map(travel => (
              <Travel key={travel.id} travel={travel} />
            ))}
          </Main>

          <Footer>
            <Pagination
              pages={pages}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={pageChange}
              onNextPage={nextPage}
              onPreviousPage={previousPage}
            />
          </Footer>
        </>
      ) : (
        <StatusMessage>
          <IoIosAlert size="80px" color="var(--color-strong-grey)" />

          <p>
            {query
              ? 'Nenhuma viagem encontrada!'
              : 'Nenhuma viagem cadastrada!'}
          </p>
        </StatusMessage>
      )}
    </Container>
  );
}
