import { mergeClassNames } from '@hitechline/reactools';
import { ButtonHTMLAttributes, ReactElement, useContext } from 'react';

import { FormContext } from '@screen/components/forward/Form';
import { Spinner } from '@screen/components/ui/Spinner';

import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  iconRight?: ReactElement;
}

export function Button({
  type,
  className,
  children,
  iconRight,
  ...rest
}: ButtonProps): JSX.Element {
  const { loading } = useContext(FormContext);

  return (
    <Container
      type={type ?? 'button'}
      className={mergeClassNames(className, { loading })}
      {...rest}
    >
      {loading ? (
        <Spinner size="20px" />
      ) : (
        <>
          {children}

          {iconRight && iconRight}
        </>
      )}
    </Container>
  );
}
